import React, { useState, useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import * as pdfjsLib from 'pdfjs-dist/webpack';

const App = () => {
  const [barcodes, setBarcodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const barcodeRefs = useRef({});

  useEffect(() => {
    const savedBarcodes = JSON.parse(localStorage.getItem('savedBarcodes') || '[]');
    if (savedBarcodes.length) {
      setBarcodes(savedBarcodes);
      setCompleted(true);
    }
  }, []);

  const getColorForFixtureLocation = (location, isScanned) => {
    const colorTrends2025 = {
      '1': '#9FB5C1',
      '2': '#B3A394',
      '3': '#D9CFC1',
      '4': '#A7BDB1',
      '5': '#E1D4C4',
      '6': '#C2A48B',
    };
    return isScanned ? '#d3d3d3' : colorTrends2025[location] || '#ECECEC';
  };

  const handlePDFUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoading(true);
    setCompleted(false);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const typedArray = new Uint8Array(event.target.result);
      const pdf = await pdfjsLib.getDocument(typedArray).promise;
      const extractedUPCs = [];

      let currentSegment = '';
      let currentFixtureLocation = '';

      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const textContent = await page.getTextContent();

        textContent.items.forEach((item, index) => {
          const text = item.str;

          const segmentMatch = text.match(/In Segment: (\d+)/);
          if (segmentMatch) {
            currentSegment = segmentMatch[1];
          }

          const fixtureLocationMatch = text.match(/Fixture Location: (\d+)/);
          if (fixtureLocationMatch) {
            currentFixtureLocation = fixtureLocationMatch[1];
          }

          const upcMatch = text.match(/\b\d{14}\b/);
          if (upcMatch) {
            const upc = upcMatch[0];
            const modifiedUPC = upc.slice(2);

            const precedingText = index > 0 ? textContent.items[index - 2].str : '';
            const followingText = index < textContent.items.length - 1 ? textContent.items[index + 2].str : '';

            extractedUPCs.push({
              id: extractedUPCs.length,
              sequence: extractedUPCs.length + 1,
              upc: modifiedUPC,
              precedingText,
              followingText,
              segment: currentSegment,
              fixtureLocation: currentFixtureLocation,
              isScanned: false,
            });
          }
        });
      }

      generateBarcodes(extractedUPCs);
    };

    reader.readAsArrayBuffer(file);
  };

  const generateBarcodes = (upcs) => {
    const barcodeData = upcs.map(({ id, upc, precedingText, followingText, sequence, segment, fixtureLocation, isScanned }) => {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, upc, {
        format: 'CODE128',
        displayValue: true,
      });
      return {
        id,
        upc,
        barcodeUrl: canvas.toDataURL(),
        precedingText,
        followingText,
        sequence,
        segment,
        fixtureLocation,
        isScanned,
      };
    });

    barcodeData.sort((a, b) => {
      if (a.segment === b.segment) {
        return a.fixtureLocation.localeCompare(b.fixtureLocation);
      }
      return a.segment.localeCompare(b.segment);
    });

    setBarcodes(barcodeData);
    localStorage.setItem('savedBarcodes', JSON.stringify(barcodeData));
    setLoading(false);
    setCompleted(true);
  };

  const toggleScanned = (id) => {
    const updatedBarcodes = barcodes.map((barcode) =>
      barcode.id === id ? { ...barcode, isScanned: !barcode.isScanned } : barcode
    );
    setBarcodes(updatedBarcodes);
    localStorage.setItem('savedBarcodes', JSON.stringify(updatedBarcodes));
  };

  const handleSearch = () => {
    const match = barcodes.find(
      ({ upc, precedingText, followingText, sequence }) =>
        upc.includes(searchQuery) ||
        precedingText.includes(searchQuery) ||
        followingText.includes(searchQuery) ||
        sequence.toString() === searchQuery
    );
    if (match) {
      barcodeRefs.current[match.id]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const renderGroupedBarcodes = () => {
    const groupedBarcodes = barcodes.reduce((acc, barcode) => {
      const key = `Segment ${barcode.segment} - Location ${barcode.fixtureLocation}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(barcode);
      return acc;
    }, {});

    return Object.keys(groupedBarcodes).map((group) => (
      <div key={group} style={{ marginBottom: '30px' }}>
        <h3>{group}</h3>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {groupedBarcodes[group].map(({ id, upc, barcodeUrl, precedingText, followingText, sequence, segment, fixtureLocation, isScanned }) => (
            <div
              key={id}
              ref={(el) => (barcodeRefs.current[id] = el)}
              onClick={() => toggleScanned(id)}
              style={{
                margin: '10px',
                textAlign: 'center',
                border: isScanned ? '2px solid #4CAF50' : '1px solid #ddd',
                padding: '10px',
                width: '80%',
                backgroundColor: getColorForFixtureLocation(fixtureLocation, isScanned),
                opacity: isScanned ? 0.6 : 1,
                cursor: 'pointer',
              }}
            >
              <p style={{ textDecoration: isScanned ? 'line-through' : 'none' }}><strong>Sequence #{sequence}</strong></p>
              <p><strong>Segment:</strong> {segment}</p>
              <p><strong>Fixture Location:</strong> {fixtureLocation}</p>
              <p><strong>Sku:</strong> {precedingText}</p>
              <img src={barcodeUrl} alt={`Barcode for UPC ${upc}`} style={{ width: '200px', height: 'auto', opacity: isScanned ? 0.7 : 1 }} />
              <p style={{ textDecoration: isScanned ? 'line-through' : 'none' }}><strong>UPC:</strong> {upc}</p>
              <p><strong>Desc:</strong> {followingText}</p>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const printBarcodes = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Barcodes</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 20px; }
          .barcode-container { display: flex; flex-wrap: wrap; justify-content: space-around; }
          .barcode-item {
            width: 80px; 
            border: 1px solid #ddd; 
            padding: 10px; 
            margin: 5px; 
            page-break-inside: avoid;
          }
          .barcode-item img { width: 100%; height: auto; }
          .barcode-item p { margin: 4px 0; font-size: 6px; }
          @media print {
            .barcode-container { break-inside: avoid; }
          }
        </style>
      </head>
      <body>
        <div class="barcode-container">
          ${barcodes.map(barcode => `
            <div class="barcode-item">
              <p><strong>Sequence #${barcode.sequence}</strong></p>
              <p><strong>Segment:</strong> ${barcode.segment}</p>
              <p><strong>Fixture Location:</strong> ${barcode.fixtureLocation}</p>
              <p><strong>Sku:</strong> ${barcode.precedingText}</p>
              <img src="${barcode.barcodeUrl}" alt="Barcode ${barcode.upc}" />
              <p><strong>UPC:</strong> ${barcode.upc}</p>
              <p><strong>Desc:</strong> ${barcode.followingText}</p>
            </div>
          `).join('')}
        </div>
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Planogram UPC Barcode Generator</h2>
      <input type="file" accept="application/pdf" onChange={handlePDFUpload} />

      {loading && <p>Loading... Please wait while we process the PDF.</p>}
      {completed && <p>Processing complete! Barcodes are ready.</p>}

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Search by UPC, preceding text, following text, or sequence"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ padding: '8px', width: '300px' }}
        />
        <button onClick={handleSearch} style={{ padding: '8px', marginLeft: '10px' }}>
          Search
        </button>
      </div>

      <button onClick={printBarcodes} style={{ padding: '10px 20px', marginBottom: '20px' }}>Print Barcodes</button>

      {renderGroupedBarcodes()}
    </div>
  );
};

export default App;
